"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LotDetailsModal = void 0;
var react_1 = __importStar(require("react"));
var gallery_1 = require("../../../../components/gallery");
var get_lot_1 = require("../../../../api/calls/lot/get_lot");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../reducers/slices/main");
var loader_content_1 = require("../../../../components/loader_content");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_duotone_svg_icons_1 = require("@fortawesome/pro-duotone-svg-icons");
var lot_status_tag_1 = require("../../../../components/tags/lot_status_tag");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var free_brands_svg_icons_1 = require("@fortawesome/free-brands-svg-icons");
var LotDetailsModal = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7;
    var lot_id = props.lot_id;
    var dispatch = (0, react_redux_1.useDispatch)();
    var liveAuction = (0, react_redux_1.useSelector)(function (state) { return state.auctionState; }).liveAuction;
    var _8 = react_1.default.useState(true), isLoading = _8[0], setIsLoading = _8[1];
    var _9 = react_1.default.useState(null), lot = _9[0], setLot = _9[1];
    var _10 = react_1.default.useState([]), extraData = _10[0], setExtraData = _10[1];
    (0, react_1.useEffect)(function () {
        setIsLoading(true);
        (0, get_lot_1.get_lot)({
            entity_id: lot_id,
        }).then(function (response) {
            setIsLoading(false);
            if (response.success) {
                setLot(response.content);
            }
            else {
                dispatch((0, main_1.setMessageModal)({
                    title: "Oops!",
                    messages: [
                        "Something went wrong while getting the lot details.",
                        response.message,
                    ],
                }));
            }
        });
    }, []);
    (0, react_1.useEffect)(function () {
        if (lot != null) {
            var arr = [];
            if (lot.extra_data) {
                for (var _i = 0, _a = lot.extra_data; _i < _a.length; _i++) {
                    var e = _a[_i];
                    arr.push({
                        label: "".concat(e.key, ": ").concat(e.value),
                        // icon: faWhatsapp,
                        colors: {
                            iconColor: "text-green-500",
                        },
                    });
                }
            }
            setExtraData(arr);
        }
    }, [lot]);
    return (react_1.default.createElement(loader_content_1.LoaderContent, { loading: isLoading, className: "max-w-[600px] flex flex-col items-center" },
        react_1.default.createElement("div", { className: "p-2 flex flex-col gap-4" },
            react_1.default.createElement("div", { className: "flex flex-row" },
                react_1.default.createElement("div", { className: "flex flex-col flex-1" },
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1 text-lg font-bold flex gap-1" },
                            react_1.default.createElement("div", { className: "" },
                                "# ", lot === null || lot === void 0 ? void 0 :
                                lot.lot_number),
                            react_1.default.createElement("div", { className: "" }, "-"),
                            react_1.default.createElement("div", { className: "" }, lot === null || lot === void 0 ? void 0 : lot.title)))),
                react_1.default.createElement("div", { className: "flex items-center" },
                    react_1.default.createElement(lot_status_tag_1.LotStatusTag, { auctionStatus: liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.status, lotStatus: lot === null || lot === void 0 ? void 0 : lot.status, size: "small" }))),
            react_1.default.createElement("div", { className: "flex justify-center pt-4" },
                react_1.default.createElement(gallery_1.Gallery, { images: (_a = lot === null || lot === void 0 ? void 0 : lot.images) === null || _a === void 0 ? void 0 : _a.map(function (image, index) {
                        return {
                            src: image === null || image === void 0 ? void 0 : image.uploaded_file_url,
                            alt: image === null || image === void 0 ? void 0 : image.title,
                        };
                    }) })),
            (((_c = (_b = liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.contact_details) === null || _b === void 0 ? void 0 : _b.whatsapp) === null || _c === void 0 ? void 0 : _c.number) || ((_e = (_d = liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.contact_details) === null || _d === void 0 ? void 0 : _d.phone) === null || _e === void 0 ? void 0 : _e.number) || ((_f = liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.contact_details) === null || _f === void 0 ? void 0 : _f.email)) && (react_1.default.createElement("div", { className: "text-lg font-bold" }, "Auction Contact Details")),
            react_1.default.createElement(doshx_controls_web_1.PillsControl, { size: "medium", pillSpacing: "justify-center", pills: [
                    {
                        visible: !!((_h = (_g = liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.contact_details) === null || _g === void 0 ? void 0 : _g.whatsapp) === null || _h === void 0 ? void 0 : _h.number),
                        label: "".concat((_k = (_j = liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.contact_details) === null || _j === void 0 ? void 0 : _j.whatsapp) === null || _k === void 0 ? void 0 : _k.number),
                        icon: free_brands_svg_icons_1.faWhatsapp,
                        colors: {
                            iconColor: "text-green-500",
                        },
                    },
                    {
                        visible: !!((_m = (_l = liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.contact_details) === null || _l === void 0 ? void 0 : _l.phone) === null || _m === void 0 ? void 0 : _m.number),
                        label: "".concat((_p = (_o = liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.contact_details) === null || _o === void 0 ? void 0 : _o.phone) === null || _p === void 0 ? void 0 : _p.number),
                        icon: pro_solid_svg_icons_1.faPhone,
                        colors: {
                            iconColor: "text-blue-500",
                        },
                    },
                    {
                        visible: !!((_q = liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.contact_details) === null || _q === void 0 ? void 0 : _q.email),
                        label: "".concat((_r = liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.contact_details) === null || _r === void 0 ? void 0 : _r.email),
                        icon: pro_solid_svg_icons_1.faEnvelope,
                        colors: {
                            iconColor: "text-green-500",
                        },
                    },
                ], colors: {
                    pills: {
                        shadowColor: "shadow-gray-200",
                        backgroundColor: "bg-gray-100",
                    },
                } }),
            (((_t = (_s = lot === null || lot === void 0 ? void 0 : lot.contacts) === null || _s === void 0 ? void 0 : _s.whatsapp) === null || _t === void 0 ? void 0 : _t.number) || ((_v = (_u = lot === null || lot === void 0 ? void 0 : lot.contacts) === null || _u === void 0 ? void 0 : _u.phone) === null || _v === void 0 ? void 0 : _v.number) || ((_w = lot === null || lot === void 0 ? void 0 : lot.contacts) === null || _w === void 0 ? void 0 : _w.email)) && (react_1.default.createElement("div", { className: "text-lg font-bold" }, "Lot Contact Details")),
            react_1.default.createElement(doshx_controls_web_1.PillsControl, { size: "medium", pillSpacing: "justify-center", pills: [
                    {
                        visible: !!((_y = (_x = lot === null || lot === void 0 ? void 0 : lot.contacts) === null || _x === void 0 ? void 0 : _x.whatsapp) === null || _y === void 0 ? void 0 : _y.number),
                        label: "".concat((_0 = (_z = lot === null || lot === void 0 ? void 0 : lot.contacts) === null || _z === void 0 ? void 0 : _z.whatsapp) === null || _0 === void 0 ? void 0 : _0.number),
                        icon: free_brands_svg_icons_1.faWhatsapp,
                        colors: {
                            iconColor: "text-green-500",
                        },
                    },
                    {
                        visible: !!((_2 = (_1 = lot === null || lot === void 0 ? void 0 : lot.contacts) === null || _1 === void 0 ? void 0 : _1.phone) === null || _2 === void 0 ? void 0 : _2.number),
                        label: "".concat((_4 = (_3 = lot === null || lot === void 0 ? void 0 : lot.contacts) === null || _3 === void 0 ? void 0 : _3.phone) === null || _4 === void 0 ? void 0 : _4.number),
                        icon: pro_solid_svg_icons_1.faPhone,
                        colors: {
                            iconColor: "text-blue-500",
                        },
                    },
                    {
                        visible: !!((_5 = lot === null || lot === void 0 ? void 0 : lot.contacts) === null || _5 === void 0 ? void 0 : _5.email),
                        label: "".concat((_6 = lot === null || lot === void 0 ? void 0 : lot.contacts) === null || _6 === void 0 ? void 0 : _6.email),
                        icon: pro_solid_svg_icons_1.faEnvelope,
                        colors: {
                            iconColor: "text-green-500",
                        },
                    },
                ], colors: {
                    pills: {
                        shadowColor: "shadow-gray-200",
                        backgroundColor: "bg-gray-100",
                    },
                } }),
            (lot === null || lot === void 0 ? void 0 : lot.location) && (react_1.default.createElement("div", { className: "flex gap-2 justify-end items-center" },
                react_1.default.createElement("span", { className: "text-lg text-black" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCircleLocationArrow })),
                react_1.default.createElement("div", { className: "cursor-pointer", onClick: function () {
                        window.open("https://maps.google.com?q=".concat(lot.location.latitude, ",").concat(lot.location.longitude), "_blank");
                    } },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "medium", label: lot === null || lot === void 0 ? void 0 : lot.location.full_address, textColor: "text-black" })))),
            react_1.default.createElement("div", { className: "text-sm" }, lot === null || lot === void 0 ? void 0 : lot.description),
            react_1.default.createElement("div", { className: "flex justify-center pt-4" },
                react_1.default.createElement("div", { className: "pt-4 max-w-96" }, extraData && (react_1.default.createElement(doshx_controls_web_1.PillsControl, { size: "small", pillSpacing: "justify-center", pills: extraData, colors: {
                        pills: {
                            shadowColor: "shadow-gray-200",
                            backgroundColor: "bg-cyan-600",
                            textColor: "text-white",
                        },
                    } })))),
            react_1.default.createElement("div", { className: "pt-4" },
                react_1.default.createElement("div", { className: "text-md font-bold" }, "Documents"),
                react_1.default.createElement("div", { className: "flex flex-wrap gap-4" }, ((_7 = lot === null || lot === void 0 ? void 0 : lot.documents) === null || _7 === void 0 ? void 0 : _7.length) > 0 ? (lot.documents.map(function (document, index) {
                    return (react_1.default.createElement("div", { key: index },
                        react_1.default.createElement("a", { href: document.uploaded_file_url, target: "_blank", rel: "" },
                            react_1.default.createElement("div", { className: "flex gap-4 items-center py-2 hover:underline" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_duotone_svg_icons_1.faFilePdf, size: "lg" }),
                                document.uploaded_file_url.split("/").pop()))));
                })) : (react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(react_1.default.Fragment, null, "No documents for this lot."))))))));
};
exports.LotDetailsModal = LotDetailsModal;
